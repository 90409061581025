/** Modal filtre tache **
------------------------------------------------------------
------------------------------------------------------------*/
.filtreParFacturation, .filtreParPole {
  margin-left: -5px;
}

.filtreParFacturation label, .filtreParPole label{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filtreParPole input{
  width: 100px;
  height: 30px;
  position: relative;
  left: 14px;
  opacity: 0;
}

.filtreParFacturation input:checked + label {
  background-color: #00E1FF;
  color: black;
}

.filtreParPole input:checked + label {
  border: solid 1px #0042FF;
  color: #0042FF;
}

#modalFiltreTache .filtreParPersonne {
  margin-top: -33px;
}

.filtreParPersonne input:checked + label img {
  border-color: #0042ff;
}

/** Modal filtre sites **
------------------------------------------------------------
------------------------------------------------------------*/
#modalFiltreSite .filtreParPersonne input {
  position: relative;
  z-index: 1;
  opacity: 0;
  left: 40px;
  top: 12px;
  height: 30px;
  width: 30px;
}

/** Modal notifications **
------------------------------------------------------------
------------------------------------------------------------*/
#notifications-modal{
  -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.modal-notifications .text-notification p{
  margin-bottom: 0!important;
}
.icone-notification {
  background: #b3dcff;
  border-radius: 35px;
  width: 50px;
  display: flex!important;
  height: 50px;
  align-items: center;
  color: #0042FF;
  justify-content: center;
}
.time-notification:after {
  content: " ";
  background-color: #A2A2A2;
  margin: 0 2px;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  display: inline-block;
}
/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.modal-notifications .modal-content{
  height: 100%;
  border-radius: 0px;
  border: none;
}

.modal-notifications .modal-content .modal-body{
  max-height: 100%!important;
}

.modal-notifications .modal-content .modal-header{
  border-bottom: none;
}